.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.absolute-full-size {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
