.icon-button {
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  background-color: transparent;
  padding: 0;
  border: none;

  &.border {
    border: 1px solid var(--primary);
  }

  &.fill {
    background: var(--primary);

    &:hover {
      background: var(--primary-hover);
    }

    .mat-icon path {
      fill: var(--white);
    }
  }

  &:disabled {
    cursor: default;

    &.border {
      border: 1px solid var(--disabled);
    }

    .mat-icon path {
      fill: var(--disabled);
    }
  }

  &:not([disabled]):not(.fill) {
    &.border {
      border: 1px solid var(--primary);
    }

    .mat-icon path {
      fill: var(--primary);
    }

    &:hover {
      &.border {
        border: 1px solid var(--primary-hover);
      }

      .mat-icon path {
        fill: var(--primary-hover);
      }
    }
  }
}

.mat-icon.white path {
  fill: var(--white);
}
