.form-label {
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 4px;

  &.small-font {
    font-size: 12px;
    margin-bottom: 0;
  }

  &.warn {
    color: var(--warn);
  }

  &.disabled {
    opacity: 0.3;
  }
}

.mat-mdc-form-field {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--black);
    font-weight: 400;

    &::placeholder {
      color: var(--black);
      opacity: 0.4;
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mat-mdc-form-field-flex {
      align-items: center;
    }

    .mat-mdc-form-field-infix {
      padding-top: 9px;
      padding-bottom: 7px;
      min-height: 42px;
    }
  }
}

.form-small-label {
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-transform: uppercase;
}

.input-hint.mat-mdc-form-field {
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
    padding: 0 8px 16px;
    font-size: 12px;
    opacity: 0.6;
  }
}

.white-background.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    background: var(--white);
  }
}

.radius-12.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    border-radius: 12px;

    .mdc-notched-outline__leading {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

.radius-6.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  border-radius: 6px;
  padding: 0 8px 0 16px;

  .mdc-notched-outline__leading {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .mdc-notched-outline__trailing {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.border.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  &:not(.mdc-text-field--disabled) {
    &,
    &:hover {
      .mdc-notched-outline .mdc-notched-outline__leading,
      .mdc-notched-outline .mdc-notched-outline__trailing {
        border-width: 1px;
        border-color: var(--border-color);
      }
    }
  }
}

.border:not(.outline-dropdown).mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  &.mdc-text-field--focused {
    .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-notched-outline .mdc-notched-outline__trailing {
      border-width: 1px;
      border-color: var(--primary);
    }
  }
}

.no-border.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border: none;
    }
  }
}

.disabled-text-black.mat-mdc-form-field {
  &.mat-form-field-disabled .mat-mdc-form-field-textarea-control {
    color: var(--black);
  }
}

.big-font.mat-mdc-form-field {
  .mdc-text-field__input {
    line-height: 20px;
    font-size: 20px;
    opacity: 0.6;

    &.input-value {
      font-weight: 700;
      opacity: 1;
    }
  }
}

.small-input.mat-mdc-form-field {
  height: 28px;

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    padding-left: 12px;
    padding-right: 12px;

    .mat-mdc-form-field-infix {
      min-height: 28px;
      padding-top: 0;
      padding-bottom: 0;
    }

    input.mat-mdc-form-field-input-control {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.input-with-icon-button {
  .mat-mdc-form-field-infix {
    padding-right: 32px;

    textarea {
      padding-right: 32px;

      &::-webkit-scrollbar-button:end:decrement {
        height: 30px;
        display: block;
        background: transparent;
      }
    }
  }
}
