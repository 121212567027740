.capitalized {
  text-transform: capitalize;
}

.trimmed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-break {
  word-wrap: break-word;
}

b {
  font-weight: bold;
}

i {
  font-style: italic;
}

.pre-line {
  white-space: pre-line;
}
