.dropdown.mat-mdc-menu-panel {
  width: 160px;
  transform-origin: top !important;

  .mat-mdc-menu-content {
    background-color: var(--white);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;

    .mat-mdc-menu-item {
      min-height: 28px;
      padding: 0 8px;
    }

    .mat-mdc-menu-item:not([disabled]):hover {
      background-color: var(--white);
    }

    .mat-mdc-menu-item-text {
      color: var(--black);
      font-size: 12px;
    }
  }
}

.multi-select-panel,
.single-select-panel,
.table-select-panel {
  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-mdc-option {
    padding-right: 0;
    height: 40px;

    &.mdc-list-item--selected {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }

    .mdc-list-item__primary-text {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.multi-select-panel {
  .mat-icon {
    width: 14px;
    height: 16px;
    color: var(--primary);

    path {
      fill: var(--primary);
    }
  }
}

.single-select-panel {
  .mdc-list-item--selected {
    display: none;
  }
}

.outline-dropdown.mat-mdc-form-field {
  &.no-padding {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.mat-focused {
    &.panel-above {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.panel-below {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .mat-mdc-select-placeholder,
  .mat-mdc-select-value-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
}

.custom-arrow.mat-mdc-form-field {
  .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  &.mat-focused {
    .dropdown-icon {
      transform: rotate(0deg);
    }
  }

  .dropdown-icon {
    width: 16px;
    height: 21px;
    padding: 10px 12px;
    transform: rotate(-180deg);
    transition: all 0.2s ease-in-out;

    &.disabled {
      opacity: 0.5;
    }
  }

  .mat-mdc-select-value {
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

.cdk-overlay-pane {
  &:not(.mat-mdc-select-panel-above) .mat-mdc-select-panel.outline-dropdown-panel {
    border-top-color: var(--white);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.mat-mdc-select-panel-above {
    .mat-mdc-select-panel.outline-dropdown-panel {
      border-bottom-color: var(--white);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.small-dropdown.mat-mdc-form-field {
  .mat-mdc-select-value {
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value-text {
      font-size: 16px;
    }
  }
}

.cdk-overlay-pane .mat-mdc-select-panel.outline-dropdown-panel.top-border {
  border-top: 1px solid var(--border-color);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.mat-mdc-select-panel.outline-dropdown-panel {
  padding: 0;
  box-shadow: none;
  border: 1px solid var(--border-color);
  max-height: 290px;

  .mat-mdc-option {
    transition: all 0.2s ease-in-out;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var(--item-hover-color);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-blue-color);
    border-radius: 20px;
  }
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-blue-color);
    border-radius: 20px;
  }
}

.mat-mdc-select-panel.filterable {
  .mat-mdc-form-field {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
  }

  .select-items-scroll-container {
    overflow: auto;
    scroll-behavior: smooth;
    height: calc(100% - 45px);
  }
}

.mat-mdc-select-panel {
  &.scrollable,
  .scrollable-content {
    @include scroll;
  }
}
