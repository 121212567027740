.status-circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-flex;

  &.new {
    background: #999;
  }

  &.deactivated {
    background: var(--orange);
  }

  &.activated {
    background: var(--green);
  }
}
