.table.mat-mdc-table {
  width: 100%;
  table-layout: fixed;
  background: var(--feature-background);
  border-spacing: 0 4px;

  .mat-mdc-header-cell {
    color: var(--black);
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 300;
  }

  .mat-mdc-row {
    .cdk-column-action {
      opacity: 0;
      transition: opacity 300ms ease-out;
    }

    &.selected,
    &:hover {
      background-color: var(--item-hover-color);
      transition: background-color 300ms ease-out;

      .cdk-column-action {
        opacity: 1;
      }
    }
  }

  .mat-mdc-cell {
    color: var(--black);
    font-size: 16px;
    height: 48px;
    padding-top: 5px;
    padding-bottom: 5px;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    border: none;
  }

  .status-icon {
    &::before {
      content: '';
      display: inline-block;
      height: 9px;
      width: 9px;
      border-radius: 5px;
      margin-right: 8px;
    }

    &.activated::before {
      background-color: var(--green);
    }

    &.deactivated::before {
      background-color: var(--orange);
    }
  }
}

.prompts-table {
  .cdk-column-name,
  .cdk-column-status,
  .cdk-column-categories {
    width: 25%;
  }

  .cdk-column-action {
    flex: none;
    width: 100px;
  }

  .cdk-column-version {
    flex-grow: 1;
  }
}

.prompt-chains-table {
  .cdk-column-name,
  .cdk-column-status {
    width: 25%;
  }

  .cdk-column-action {
    flex: none;
    width: 100px;
  }

  .cdk-column-categories {
    flex-grow: 1;
  }
}

.categories-table {
  .cdk-column-name {
    width: 20%;
  }

  .cdk-column-prompts,
  .cdk-column-chains {
    flex-grow: 1;
  }

  .cdk-column-action {
    width: 100px;
  }
}

.members-table {
  padding: 0 160px 0 130px;

  .cdk-column-name,
  .cdk-column-email,
  .cdk-column-role {
    flex-grow: 1;
  }

  .cdk-column-action {
    text-align: right;
    width: 100px;
  }
}
