.tab.mat-mdc-tab-link {
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  min-width: 0;
  margin-right: 16px;

  &:not(.mdc-tab--stacked) {
    height: 24px;
  }

  &.mdc-tab--active .mdc-tab__ripple::before,
  &.mdc-tab--active .mat-ripple-element,
  .mdc-tab__ripple::before {
    background-color: transparent;
  }

  .mdc-tab__text-label,
  &:hover .mdc-tab__text-label {
    color: var(--black);
  }
}

.tabs {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    padding: 0 40px;
    border-bottom: 1px solid var(--border-color);

    &:hover .mdc-tab__text-label {
      color: var(--primary);
    }
  }

  &.mat-mdc-tab-group,
  &.mat-mdc-tab-nav-bar {
    .mdc-tab__text-label {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      color: var(--primary);
    }
  }

  .mdc-tab__ripple {
    display: none;
  }
}

.prompt-chain-creation-tabs.mat-mdc-tab-group {
  .mat-mdc-tab-body-wrapper {
    justify-content: center;

    .prompt-chain-create {
      max-width: 520px;
    }

    .prompt-chain-test {
      //max-width: 720px;
    }
  }
}
