@mixin icon($icon-name) {
  background-image: url(/assets/svg/#{$icon-name}.svg);
  background-size: cover;
  height: 20px;
  width: 20px;
}

.hot-toast-bar-base-container {
  .hot-toast-bar-base {
    min-height: 64px;
    border-radius: 6px;
  }

  .hot-toast-icon {
    align-self: center;
    padding: 0;
    margin: 0 4px 0 12px;
  }

  .hot-toast-message {
    font-family: var(--font-family);
  }

  .hot-toast-close-btn {
    cursor: pointer;
    background-image: url('/assets/svg/close.svg');
    height: 10px;
    width: 10px;
    padding: 0;
    margin-left: 16px;
    opacity: 1;

    &:hover,
    &:focus {
      opacity: 1;
      box-shadow: none;
    }
  }
}

.error-toast.hot-toast-bar-base {
  margin-right: 130px;
  margin-bottom: 20px;

  .hot-toast-animated-icon {
    @include icon('error');
  }
}

.success-toast.hot-toast-bar-base {
  margin-top: calc(50vh - 32px);

  .hot-toast-animated-icon {
    @include icon('check');
  }
}
