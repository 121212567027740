.fxrow {
  flex-direction: row;
}

.fxcolumn {
  flex-direction: column;
}

.fxgrow {
  flex-grow: 1;
}

.fxstart {
  align-items: flex-start;
}

.fxstartstart {
  align-items: flex-start;
  justify-content: flex-start;
}

.fxstartend {
  align-items: flex-end;
  justify-content: flex-start;
}

.fxendstart {
  align-items: flex-start;
  justify-content: flex-end;
}

.fxspacestart {
  align-items: flex-start;
  justify-content: space-between;
}

.fxspaceend {
  align-items: flex-end;
  justify-content: space-between;
}

.fxspacecenter {
  align-items: center;
  justify-content: space-between;
}

.fxcenterend {
  align-items: center;
  justify-content: flex-end;
}

.fxstartcenter {
  align-items: center;
  justify-content: flex-start;
}

.fxstartbetween {
  align-items: flex-start;
  justify-content: space-between;
}

.fxcenterbetween {
  align-items: center;
  justify-content: space-between;
}

.fxendcenter {
  align-items: center;
  justify-content: flex-end;
}

.fxcentercenter {
  align-items: center;
  justify-content: center;
}

.fxcenterstart {
  align-items: flex-start;
  justify-content: center;
}

.fxspacebetween {
  justify-content: space-between;
}

.fxstartstretch {
  justify-content: flex-start;
}

.fxspacebetweenstretch {
  justify-content: space-between;
}

.fxflex,
.fxrow,
.fxcolumn {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.fxend {
  justify-content: flex-end;
}

.fxendend {
  align-items: flex-end;
  justify-content: flex-end;
}

.fxgap2px {
  gap: 2px;
}

.fxgap5px {
  gap: 5px;
}

.fxgap8px {
  gap: 8px;
}

.fxgap10px {
  gap: 10px;
}

.fxgap12px {
  gap: 10px;
}

.fxgap17px {
  gap: 17px;
}

.fxgap20px {
  gap: 20px;
}

.fxoffset5px {
  margin-left: 5px;
}

.fxoffset10px {
  margin-left: 10px;
}

.fxoffset13px {
  margin-left: 13px;
}

.fxoffset17px {
  margin-left: 17px;
}

.fxoffset20px {
  margin-left: 20px;
}

$sizesInPercentages: 7, 10, 20, 30, 32, 40, 44, 45, 50, 56, 60, 70, 100;

@each $size in $sizesInPercentages {
  .fxflex#{$size}pct {
    flex: 1 1 100%;
    max-width: $size * 1%;
  }
}

$sizesInPx: 10, 15, 24, 57, 100, 120, 150, 200;

@each $size in $sizesInPx {
  .fxflex#{$size}px {
    flex: 1 1 $size * 1px;
    max-width: $size * 1px;
    min-width: $size * 1px;
  }
}

.fxwide {
  flex: 1 1 0;
}

.fxwrap {
  flex-wrap: wrap;
}
