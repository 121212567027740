.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 12px;

    & > :first-child {
      display: flex;
      height: 100%;
    }
  }

  .mdc-dialog__actions {
    app-button {
      margin-left: 8px;
    }
  }
}
