:root {
  --primary: #004fcc;
  --primary-hover: #0018a3;
  --light-primary: #99b9eb;
  --black: #1d1d1f;
  --white: #fff;
  --violet: #6500db;
  --light-violet: #a09dd5;
  --dark-violet: #413baa;
  --dark-gray: #1d1d1f;
  --gray: #9e9e9f;
  --feature-background: #f4f4f4;
  --note-background: #f4f4f4;
  --selected-item-background: #f4f4f4;
  --scrollbar-color: #d2d2d2;
  --scrollbar-color-hover: #a5a5a5;
  --scrollbar-track-hover: #eee;
  --scrollbar-blue-color: #d9e5f7;
  --warn: #ff3137;
  --disabled: #b3caf0;
  --green: #1fb076;
  --orange: #ed9200;
  --border-color: #baceed;
  --item-hover-color: #d9e5f7;
  --font-family: 'Nunito Sans', sans-serif;
}
