.main-button {
  cursor: pointer;
  font-family: var(--font-family);
  border-radius: 6px;
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  .mat-icon {
    margin-left: -6px;
    height: 20px;
    width: 20px;
  }

  &.primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--white);

    &:not([disabled]):hover {
      background-color: var(--primary-hover);
      border: 1px solid var(--primary-hover);
    }
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);

    &:hover {
      border: 1px solid var(--primary-hover);
      color: var(--primary-hover);

      .mat-icon path {
        fill: var(--primary-hover);
      }
    }
  }

  &.tertiary {
    background-color: transparent;
    border: none;
    color: var(--primary);
    font-weight: 700;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.3;
  }
}
