html,
body {
  margin: 0;
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}
