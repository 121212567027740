.mat-mdc-slide-toggle .mdc-switch {
  .mdc-switch__handle:has(.mdc-switch__icons),
  &:active:not(.mdc-switch--disabled) .mdc-switch__handle:has(.mdc-switch__icons) {
    height: 18px;
    width: 18px;
  }
}

button.mdc-switch {
  min-width: 50px;

  .mdc-switch__track {
    border: 1px solid var(--primary);
    height: 24px;
    border-radius: 12px;
  }

  &:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
  &:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before,
  &:enabled:focus:not(:active) .mdc-switch__track::after,
  &:enabled:focus:not(:active) .mdc-switch__track::before,
  &:enabled:active .mdc-switch__track::after,
  &:enabled:active .mdc-switch__track::before {
    background: transparent;
  }

  &.mdc-switch--disabled {
    opacity: 0.5;

    & + .mdc-label {
      opacity: 0.5;
    }
  }

  &.mdc-switch--unselected {
    .mdc-switch__handle-track {
      left: 4px;
    }

    .mdc-switch__shadow {
      background: var(--primary);
    }

    .mdc-switch__track::after,
    .mdc-switch__track::before,
    &:enabled .mdc-switch__handle::after,
    &:enabled:focus:not(:active) .mdc-switch__handle::after,
    &:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
    &.mdc-switch--disabled .mdc-switch__handle::after {
      background: transparent;
    }

    &.mdc-switch--disabled {
      .mdc-switch__track {
        border-color: var(--light-primary);
        opacity: 1;
      }

      .mdc-switch__shadow {
        background: var(--light-primary);
      }
    }

    &:not(.mdc-switch--disabled):hover {
      .mdc-switch__track {
        border-color: var(--primary-hover);
      }

      .mdc-switch__shadow {
        background: var(--primary-hover);
      }
    }

    .mdc-switch__icon {
      display: none;
    }
  }

  &.mdc-switch--selected {
    .mdc-switch__track {
      background: var(--primary);
    }

    .mdc-switch__shadow {
      background: white;
      border: 1px solid var(--primary);
    }

    .mdc-switch__icons {
      opacity: 1;
    }

    .mdc-switch__icon {
      fill: var(--primary);
      height: 11px;
    }

    .mdc-switch__track::after,
    .mdc-switch__track::before {
      background: transparent;
    }

    &:not(.mdc-switch--disabled):hover {
      .mdc-switch__track {
        border-color: var(--primary-hover);
        background: var(--primary-hover);
      }

      .mdc-switch__shadow {
        background: white;
        border-color: var(--primary-hover);
      }

      .mdc-switch__icon {
        fill: var(--primary-hover);
      }
    }

    &.mdc-switch--disabled {
      .mdc-switch__track {
        border-color: var(--light-primary);
        background: var(--light-primary);
        opacity: 1;
      }

      .mdc-switch__shadow {
        background: white;
        border-color: transparent;
      }

      .mdc-switch__icon {
        fill: var(--light-primary);
      }
    }
  }

  .mdc-switch__ripple {
    display: none;
  }
}
