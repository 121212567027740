.chip {
  &.primary,
  &.violet,
  &.black {
    .mat-icon path {
      fill: var(--white);
    }
  }

  &.light-primary,
  &.light-violet {
    .mat-icon path {
      fill: var(--black);
    }
  }
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 12px;
}
