.scroll-container {
  overflow: auto;
  scroll-behavior: smooth;
  height: 100%;
}

.background-element {
  background: var(--feature-background);
  left: 110px;
  right: 110px;
  border-radius: 24px 24px 0 0;
  height: calc(100vh - 126px);
  width: calc(100vw - 220px);
}

.background {
  background: var(--feature-background);
  border-radius: 24px;
  width: 100%;
  padding: 40px;
}

@mixin scroll($color) {
  &::-webkit-scrollbar {
    width: 16px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid $color;
    background: var(--scrollbar-color);
    border-radius: 8px;
  }
}

.white-scrollbar {
  @include scroll(var(--white));
}

.background-scrollbar {
  @include scroll(var(--feature-background));
}

.blue-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-blue-color);
    border-radius: 20px;
  }
}
