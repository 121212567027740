:root .mat-mdc-menu-panel {
  box-shadow: none;
  border-radius: 6px;

  .mat-mdc-menu-item {
    min-height: 40px;
    min-width: 265px;

    .mat-mdc-menu-item-text {
      color: var(--primary);
    }

    &:hover:not([disabled]) {
      background-color: white;

      .mat-mdc-menu-item-text {
        color: var(--primary-hover);
      }
    }
  }
}
