textarea {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-blue-color);
    border-radius: 4px;
  }
}
