.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-03 {
  opacity: 0.3;
}

.visibility-hidden {
  visibility: hidden;
}

.display-contents {
  display: contents;
}

.opacity-hidden {
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.display-block {
  display: block;
}
