.red-hover:hover {
  color: #f44336;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.no-pointer-events {
  pointer-events: none;
}
