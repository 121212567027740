.mat-mdc-form-field {
  input.mat-mdc-input-element.mat-mdc-form-field-input-control {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    &::placeholder {
      color: var(--gray);
      font-weight: 400;
    }
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-error {
    color: var(--warn);
    line-height: 8px;
    min-height: 20px;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
