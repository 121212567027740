.black-text {
  color: var(--black);
}

.section-label {
  color: var(--black);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.question {
  margin: 12px 0;
  line-height: 20px;
  font-size: 18px;
  font-weight: 300;
  min-height: 26px;

  &.enlarged {
    margin: 12px 0 68px;
    font-size: 24px;
    line-height: 24px;
  }

  app-chip {
    display: block;
    padding: 0 5px;
  }
}
